const OneTimeCodeView = ({ onetimeCode, clearOnetimeCode }) => {
  return (
    <div className="login-block">
      <div className="login-block-card">
        <div className="login-card-content">
          <div>
            <div className="login-card-content__icon">
              <i className="fa-light fa-lock"></i>
            </div>
            <h2 className="login-card-content__code">{onetimeCode}</h2>
            <p className="login-card-content__text">
              Share this code with your children to allow them to login with
              their username
            </p>
          </div>
          <button
            className="btn btn--primary"
            type="button"
            onClick={() => {
              clearOnetimeCode();
              
            }}
          >
            {" "}
            Continue{" "}
          </button>
        </div>
      </div>
    </div>
  );
};

export default OneTimeCodeView;
